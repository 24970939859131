
  import { Component, Vue } from 'vue-property-decorator';
  import { Mutation } from 'vuex-class';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import { FETCH_SETTINGS, UPDATE_SETTING, RESET_STATE, SET_SETTING_VALUE, SET_DEFAULT_SPORTS } from '@/store/settings';
  import {Setting, Sport} from '@/types/entities';
  import { FETCH_SPORTS } from '@/store/sports';
  import { SettingMultiSelectMode } from '@/types/enums';

  @Component({
    components: { InspiniaIbox, HipsterSelector }
  })
  export default class SettingsList extends Vue {
    @Mutation(SET_SETTING_VALUE) setValue: (data: { setting: Setting, key: string, value: any }) => void;
    @Mutation(SET_DEFAULT_SPORTS) setDefaultSports: (data: any) => void;

    async beforeMount() {
      this.$store.commit('setTitle', 'Settings');
      this.$store.commit(RESET_STATE);
      
      await this.$store.dispatch(FETCH_SETTINGS);
      await this.$store.dispatch(FETCH_SPORTS, { is_supported: 1, all: 1 });
    }

    public save(setting: Setting): void {
      this.$store.dispatch(UPDATE_SETTING, setting)
        .then(() => this.$store.dispatch(FETCH_SETTINGS));
    }

    public selectDefaultSports(selectedSports: Array<Sport>): void {
      const defaultSports = selectedSports.map((selectedSport) => selectedSport.slug);
      
      this.setDefaultSports(defaultSports);
    }

    public isDefaultSportsSetting(settingName: string): boolean {
      return settingName === SettingMultiSelectMode.DEFAULT_SPORTS;
    }

    public get selectedSports(): Array<string> {
      const defaultSportsSetting = [...this.$store.state.settings.itemsList.data]
        .find((item: Setting) => this.isDefaultSportsSetting(item.name));

      return this.$store.state.sports.sportsList.data
        .filter((item: Sport) => defaultSportsSetting.value.includes(item.slug));
    }
  }
